import {Component, Inject, OnInit} from '@angular/core';
import {User} from '../../../../shared/models/user.model';
import {Activetour} from '../../../../shared/models/activetour.model';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../../shared/services/common/snackbar.service';
import {AuthenticationService} from '../../../../shared/services/session/authentication.service';
import {HttpComponentService} from '../../../../shared/services/http/http-component.service';
import {ActivetourService} from '../../../../shared/services/session/activetour.service';
import {Components} from '../../../../shared/models/components.model';
import {takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {Comptype} from '../../../../shared/models/comptype.model';
import {HttpCityService} from '../../../../shared/services/http/http-city.service';
import {City} from '../../../../shared/models/city.model';

@Component({
  selector: 'app-copy-from',
  templateUrl: './copy-from.component.html',
  styleUrls: ['./copy-from.component.scss']
})
export class CopyFromComponent implements OnInit {
  currentUser: User;
  activeTour: Activetour;
  allComponents: Components[];
  source_component: Components;
  baseUrlLogo: string;
  loading = false;
  loading_comps = false;
  comp_type: Comptype;
  td: string;
  tds: string[] = [];
  compTypes: Comptype[] = [];
  cities: City[] = [];
  city_id: string;
  city_name: string;

  private onDestroy$ = new Subject<boolean>();

  constructor(public dialogRef: MatDialogRef<CopyFromComponent>,
              private snackSvc: SnackbarService,
              private httpCitySvc: HttpCityService,
              private authSvc: AuthenticationService,
              private httpComp: HttpComponentService,
              private activeTourSvc: ActivetourService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.authSvc.currentUser
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(user => {
        this.currentUser = user;
      });
    this.activeTourSvc.activeTour
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(tour => {
        this.activeTour = tour;
      });
    this.compTypes = JSON.parse(localStorage.getItem('compTypes'));
    this.cities = JSON.parse(localStorage.getItem('cities'));
    this.cities = this.cities.filter(c => c.name.toLowerCase() !== 'city');
    localStorage.setItem('cities', JSON.stringify(this.cities));
    this.compTypes = this.compTypes.filter(ct => ct.id !== 1 && ct.id !== 7 && ct.id !== 22 && ct.id !== 23);
    this.comp_type = this.compTypes.find(ct => +ct.id === +this.data.component.comp_type_id);
    this.city_id = this.data.component.city_id;
    this.city_name = this.data.component.city_name;
  }

  ngOnInit(): void {
    this.baseUrlLogo = environment.baseUrl;
    this.onGetSimilarComponents();
  }

  onGetSimilarComponents() {
    this.allComponents = [];
    if (!this.city_id && !this.comp_type?.id) {
      return;
    }
    this.loading_comps = true;
    const data = {
      'req_id': this.currentUser.id,
      'component_id': this.data.component.id,
      'city_id': this.city_id,
      'comp_type_id': this.comp_type?.id,
    };
    // console.log(data);
    this.httpComp.getSimilarComponents(data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          this.loading_comps = false;
          console.log(res);
          if (res.status < 400) {
            this.allComponents = res.results.component;
            this.tds = Array.from(new Set(this.allComponents.map(item => item.td)));
          } else {
            this.snackSvc.resultsElse(res);
          }
        },
        error => {
          this.loading_comps = false;
          console.log(error);
          this.snackSvc.openSnackBar('Error getting similar components');
        });
  }

  toggleSel(txt, comp) {
    if (!this.source_component || comp.id !== this.source_component.id) {
      this.allComponents.map(c => c.selected = []);
      this.source_component = comp;
      this.source_component.selected = [];
    }
    if (txt !== '0') {
      if (!this.source_component.selected?.includes(txt)) {
        this.source_component.selected.push(txt);
      } else {
        const idx = this.source_component.selected.findIndex(i => i === txt);
        this.source_component.selected.splice(idx, 1);
      }
    }
  }

  onSelectCity(e) {
    this.city_id = e.value;
    this.city_name = this.cities.find(c => +c.id === +this.city_id).name;
    this.onGetSimilarComponents();
  }

  onSelectionTypeChange(e) {
    this.comp_type = this.compTypes.find(ct => +ct.id === +e.value);
    this.onGetSimilarComponents();
  }

  onSelectionTDChange(e) {
    this.td = this.tds.find(td => td === e.value);
    this.allComponents = this.allComponents.filter(c => c.td === this.td);
  }

  submit(): void {
    if (!this.source_component) {
      this.snackSvc.openSnackBar('Invalid component');
      return;
    }
    this.loading = true;
    const data = {
      'mailable': this.source_component.mailable,
    };
    for (const item of this.source_component.selected) {
      if (item === 'name') {
        data['name'] = this.source_component.name;
      } else if (item === 'type') {
        data['comp_type_id'] = this.source_component.comp_type_id;
      } else if (item === 'time') {
        data['start_time'] = this.source_component.events[0].start_time;
        data['end_time'] = this.source_component.events[0].end_time;
      } else if (item === 'meet_place') {
        data['meet_place'] = this.source_component.events[0].meet_place;
      } else if (item === 'url') {
        data['url'] = this.source_component.events[0].url;
      } else if (item === 'image') {
        data['image'] = this.source_component.image;
      } else if (item === 'confirm_notes') {
        data['confirm_notes'] = this.source_component.confirm_notes;
      } else if (item === 'public_notes') {
        data['public_notes'] = this.source_component.events[0].public_notes;
      }
    }
    // console.log(data);
    this.httpComp.updateComponentEvent(this.data.component.id, data)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        res => {
          console.log(res);
          if (res.status < 400) {
            this.data.component = res.results.component;
            const compIdx = this.activeTour.components.findIndex(c => c.id === this.data.component.id);
            this.activeTour.components[compIdx] = this.data.component;
            this.activeTourSvc.setActiveTour(this.activeTour);
            this.close('save');
          } else {
            console.log('Error: component not copied');
            this.snackSvc.resultsElse(res);
          }
          this.loading = false;
        },
        error => {
          this.loading = false;
          console.log(error);
          this.snackSvc.openSnackBar('ERROR. Copying component');
        });
  }

  close(reason) {
    this.data.reason = reason;
    this.dialogRef.close(this.data);
  }
}
